import React, { useState } from 'react'
import Footer from '../../components/Footer'
import CTA from '../../components/CTA'

import './contact.scss'

const ContactUs = ({ }) => {
  const [contactUs, setContactUs] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setContactUs({ ...contactUs, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

  }
  return (
    <div className="contact-container">
      <div className="col mx-auto ">
        <div className="col mobile-pd-5">
          <h3 className="w-30 mobile-w30">Love to hear from you, Get in touch 💌</h3>
          <p className="text-small">
            Get in touch with us today and we will be more than happy to answer
            any questions you may have.
          </p>
        </div>
        <form onClick={handleSubmit}>
          <div className="col mt-2 w-100">
            <div className="row sp-bt w-100 mt-2 mobile-col">
              <input
                type={`text`}
                placeholder="&nbsp; First Name"
                className="input-text mr-2"
                name="firstName"
                onChange={(e) => handleChange(e)}
              />
              <input
                type={`text`}
                placeholder="&nbsp; Last Name"
                className="input-text"
                name="lastName"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="row sp-bt w-100 mt-2 mobile-col">
              <input
                type={`email`}
                placeholder="&nbsp; Email"
                className="input-text"
                name="email"
                onChange={(e) => handleChange(e)}
              />
              <input
                type={`text`}
                placeholder="&nbsp; How can we help you?"
                className="input-text"
                name="canWeHelp"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="row max-w-100 mt-2 mobile-col">
              <textarea
                placeholder="&nbsp; Tell us a bit about your inquiry"
                className="input-textarea"
                name="message"
                onChange={(e) => handleChange(e)}
                cols={100}
                rows={20}
              ></textarea>
            </div>
            <div className="row max-w-100">
              <CTA height="50px" width="100%" size="14px" type="primary">
                Submit
              </CTA>
            </div>
          </div>
        </form>
      </div>
      <div className="row w-100 mt-10">
        <iframe
          className="w-100 h-50"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63530.634704302094!2d-0.1900544!3d5.6164352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sgh!4v1674124208704!5m2!1sen!2sgh"
          style={{ border: '0' }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      {/* <div className="row sp-bt h-full">
        <div className="col w-60">
          <div className="ml-20 w-40">
            <h4 className="text-primary ">WHERE TO LOCATE US</h4>
            <h3 className="mt-5 sp-text-20 text-bold">
              Do You Have Any Questions or Individual Requests?
            </h3>
            <p className="mt-5 w-80 lh-30">
              Get in touch with us today and we will be more than happy to
              answer any questions you may have.
            </p>
            <div className="row mt-10">
              <div className="col">
                <h3>Address</h3>
                <span className="">Location Address</span>
              </div>
              <div className="col">
                <h3>Phone Number</h3>
                <span className="">+109 90 90 90 90 90</span>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col">
                <h3>Email</h3>
                <span className="">info@aktaanalytics.com</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col w-40">
          <h1>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63530.634704302094!2d-0.1900544!3d5.6164352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sgh!4v1674124208704!5m2!1sen!2sgh"
              width="760"
              height="970"
              style={{ border: '0' }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </h1>
        </div>
      </div> */}
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default ContactUs
